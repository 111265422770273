<script>
import { VParallax } from 'vuetify/lib'

export default {
  extends: VParallax,
  props: {
    type: {
      type: String,
      default: 'img'
    }
  },
  computed: {
    stylesVideo() {
      return {
        display: 'block',
        opacity: 1,
        transform: `translate(-50%, ${this.parallax}px)`
      }
    }
  },
  methods: {
    init() {
      const img = this.$refs.img

      if (!img) return

      if (img.complete) {
        this.translate()
        this.listeners()
      } else {
        img.addEventListener(
          'load',
          () => {
            this.translate()
            this.listeners()
          },
          false
        )
      }

      const video = this.$refs.video

      if (!video) return

      if (video.complete) {
        this.translate()
        this.listeners()
      } else {
        video.addEventListener(
          'load',
          () => {
            this.translate()
            this.listeners()
          },
          false
        )
      }
    },
    objHeight() {
      return this.$refs.img.naturalHeight
    }
  },
  render(h) {
    const imgData = {
      staticClass: 'v-parallax__image',
      style: this.styles,
      attrs: {
        src: this.src,
        alt: this.alt
      },
      ref: 'img'
    }

    const videoData = h(
      'video',
      {
        staticClass: 'v-parallax__image',
        style: this.stylesVideo,
        attrs: {
          alt: this.alt,
          width: 2200,
          autoplay: true,
          muted: true,
          loop: true
        },
        ref: 'video'
      },
      [
        h('source', {
          attrs: { src: this.src }
        })
      ]
    )

    const container = h(
      'div',
      {
        staticClass: 'v-parallax__image-container'
      },
      [this.type === 'video' ? videoData : h('img', imgData)]
    )

    const content = h(
      'div',
      {
        staticClass: 'v-parallax__content'
      },
      this.$slots.default
    )

    return h(
      'div',
      {
        staticClass: 'v-parallax',
        style: {
          height: `${this.height}px`
        },
        on: this.$listeners
      },
      [container, content]
    )
  }
}
</script>
